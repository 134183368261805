import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Shapefour from "../images/bannerElement/white-design-element-four.svg"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import "../styles/sass.css"

export default function Growing() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Keep on growing | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div className="keepGrowing">
          <section id="Banner" className="cybersecurity">
            <div className="container">
              <div className="banner-holder">
                <div className="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                    <div className="title">
                      <div className="banner-title">
                        <h1>keep on growing</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div className="container">
              <h2>Growth-centric Philosophy</h2>
              <p>
                We engage with ambitious business innovators who believe in
                growth.
              </p>
              <p>
                As a growth marketing agency, we offer clear, focused solutions
                honed over the years. <br />
                Our philosophy extends beyond revenue. We nurture holistic
                growth across all dimensions of a business.
                <br />
                From individual development to organizational culture and brand
                impact, we foster comprehensive growth strategies.
              </p>
              <p>
                With an unwavering focus on customer and organizational growth,
                our solutions pave the way for endless expansion.
              </p>
              <p>
                Our philosophy helps remarkable businesses compete and win. Our
                commitment to simplicity and directness ensures our growth
                partners can perpetually evolve. They grow as a name, as a
                brand, and as a culture.
              </p>
              <p>
                And they keep on growing with Position<sup>2</sup>.
              </p>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}
